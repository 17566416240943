import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlistCss32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M21.7 3.5c-2 0-4.2 1-5.7 2.4-2.2-2.2-5.5-3-8.4-2-2.8 1-4.9 3.7-5.5 6.8-.3 1.9-.1 3.9.6 5.7 1 2.6 2.5 4.3 6.1 7.2 1.9 1.6 3.8 3.1 5.9 4.6.1 0 .4.3.4.3.1 0 .1.1.1.1.4.3.6.4.9.4.3 0 .5-.2.9-.5l.1-.1s.4-.4.5-.4c2.2-1.6 4.2-3.2 6.1-4.9 3.3-2.8 4.8-4.6 5.7-7.3 2.1-5.7-1.5-12.3-7.7-12.3" className="wishlist-css-32_svg__fill" /><path d="M21.7 3.5c-2 0-4.2 1-5.7 2.4-2.2-2.2-5.5-3-8.4-2-2.8 1-4.9 3.7-5.5 6.8-.3 1.9-.1 3.9.6 5.7 1 2.6 2.5 4.3 6.1 7.2 1.9 1.6 3.8 3.1 5.9 4.6.1 0 .4.3.4.3.1 0 .1.1.1.1.4.3.6.4.9.4.3 0 .5-.2.9-.5l.1-.1s.4-.4.5-.4c2.2-1.6 4.2-3.2 6.1-4.9 3.3-2.8 4.8-4.6 5.7-7.3 2.1-5.7-1.5-12.3-7.7-12.3m6.8 12.3c-.8 2.5-2.3 4.2-5.4 6.8-1.9 1.6-3.9 3.2-6.1 4.8-.1.1-.5.5-.5.4-.1 0-.1 0-.1.1-.1.1-.3.2-.3.2l-.2-.2-.1-.1s-.4-.3-.4-.4c-2-1.5-4-3-5.8-4.6-3.4-2.9-4.8-4.4-5.8-6.8-.6-1.7-.8-3.5-.5-5.2.3-2.7 2.2-5.1 4.7-5.9 2.7-.9 5.8 0 7.7 2.1l.3.4.4-.4c1.4-1.5 3.5-2.5 5.3-2.5 5.4 0 8.6 5.9 6.8 11.3" className="wishlist-css-32_svg__border" /></SvgIcon>;
};
const Memo = memo(SvgWishlistCss32);
export default Memo;