import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgAppIcon = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" id="app-icon_svg__Ebene_1" viewBox="0 0 43.3 43.3" sx={{
    ...sx
  }} {...other}><defs><style>{".app-icon_svg__cls-1{fill:#fff}"}</style></defs><path d="M34.79 0H8.51A8.51 8.51 0 0 0 0 8.51v26.28c0 4.7 3.81 8.51 8.51 8.51h26.28c4.7 0 8.51-3.81 8.51-8.51V8.51c0-4.7-3.81-8.51-8.51-8.51M11.13 34.54H8.41c-1.05 0-1.47-.65-.94-1.45L22.24 10c.62-1.02.91-1.23 1.82-1.23h3.88c.93 0 1.05.77.59 1.49L13.86 33.24c-.58 1.02-.98 1.31-2.72 1.31Zm25.54-8.21c0 1.13-.27 1.49-1.1 1.49h-4.66a.96.96 0 0 0-.96.96v4.67c0 .83-.33 1.1-1.45 1.1h-2.69c-1.13 0-1.49-.27-1.49-1.1v-4.66c0-.53-.44-.96-.97-.96h-2.2c-.37 0-.59-.41-.39-.72l7.16-11.03c.74-1.12 2.07-.53 2.03.49v4.66c0 .53.43.95.95.95h4.66c.83 0 1.1.33 1.1 1.45v2.69Z" style={{
      fill: "#e10814"
    }} /><path d="M27.93 8.76h-3.88c-.91 0-1.2.22-1.82 1.23L7.46 33.08c-.52.8-.11 1.45.94 1.45h2.72c1.74 0 2.14-.29 2.72-1.31l14.67-22.98c.46-.72.34-1.49-.59-1.49ZM35.56 22.19H30.9a.95.95 0 0 1-.95-.95v-4.66c.04-1.02-1.29-1.61-2.03-.49l-7.16 11.03c-.2.31.02.72.39.72h2.2c.53 0 .96.43.96.96v4.66c0 .83.37 1.1 1.5 1.1h2.69c1.13 0 1.45-.27 1.45-1.1v-4.67c0-.53.43-.96.96-.96h4.66c.83 0 1.1-.36 1.1-1.49v-2.69c0-1.13-.27-1.45-1.1-1.45Z" className="app-icon_svg__cls-1" /></SvgIcon>;
};
const Memo = memo(SvgAppIcon);
export default Memo;