import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgService32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M15.874 28.749c7.042 0 12.75-5.708 12.75-12.75s-5.708-12.75-12.75-12.75-12.75 5.708-12.75 12.75 5.708 12.75 12.75 12.75m0 1c-7.594 0-13.75-6.156-13.75-13.75s6.156-13.75 13.75-13.75 13.75 6.156 13.75 13.75-6.156 13.75-13.75 13.75" /><path d="m18.99 14.379.006-.005a.076.076 0 0 1-.006.005m.214-3.232c0-1.795-.925-3.267-3.204-3.267-2.276 0-3.267 1.545-3.267 3.267a.622.622 0 0 1-1.244 0c0-2.324 1.402-4.511 4.511-4.511 3.12 0 4.449 2.115 4.449 4.511 0 1.168-.641 2.574-1.452 3.227-.035.026-.397.297-.518.394a6.46 6.46 0 0 0-.62.559c-.787.816-1.237 1.781-1.237 3.006v1.556a.622.622 0 0 1-1.244 0v-1.556c0-1.574.589-2.837 1.586-3.87.231-.24.473-.456.739-.668.132-.105.488-.372.533-.405.496-.406.969-1.448.969-2.243zM16.622 23.5v-1a.622.622 0 0 0-1.244 0v1a.622.622 0 0 0 1.244 0" /></SvgIcon>;
};
const Memo = memo(SvgService32);
export default Memo;