import deepmerge from '@mui/utils/deepmerge';
import { createTheme } from '@mui/material/styles';
import type { Theme, ThemeOptions } from '@mui/material/styles';
import { augmentColor, DEFAULTCOLOR_TEXT_DISABLED } from '../default/palette';
import { generateDefaultThemeOptions } from '../default';
import type { Device } from '../default';
import { fonts } from './fonts';

const DEFAULTCOLOR_TEXT_DARK = '#424242';
const DEFAULTCOLOR_TEXT_LIGHT = '#ffffff';
const DEFAULTCOLOR_TEXT_TRANSPARENT_DARK = 'rgba(66, 66, 66, 0.65)';
const DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT = 'rgba(255, 255, 255, 0.65)';
const DEFAULTCOLOR_COMMON_LIGHT = '#f5f5f5';
const DEFAULTCOLOR_PRIMARY_MAIN = '#e10000';

/** Define theme object.
 * See here for more info: https://mui.com/customization/theming/ */
const themeOptions: ThemeOptions = {
  // https://mui.com/customization/palette/
  palette: {
    primary: {
      main: DEFAULTCOLOR_PRIMARY_MAIN,
      textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
    },
    secondary: {
      main: '#2d4691',
    },
    grey: { main: '#909090', light: DEFAULTCOLOR_COMMON_LIGHT, dark: '#424242' },
    text: {
      disabled: DEFAULTCOLOR_TEXT_DISABLED,
      primary: DEFAULTCOLOR_TEXT_DARK,
      secondary: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
      dark: DEFAULTCOLOR_TEXT_DARK,
      darkTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
      light: DEFAULTCOLOR_TEXT_LIGHT,
      lightTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
    },
    footer: {
      main: DEFAULTCOLOR_COMMON_LIGHT,
      contrastText: DEFAULTCOLOR_TEXT_DARK,
    },
    sale: augmentColor({
      color: {
        main: DEFAULTCOLOR_PRIMARY_MAIN,
        contrastText: DEFAULTCOLOR_TEXT_LIGHT,
        textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
      },
    }),
    sustainability: augmentColor({
      color: {
        main: '#aab97d',
      },
    }),
  },
  typography: {
    fontFamily: fonts.primary.style.fontFamily,
    fontWeightRegular: 400,
    fontWeightBold: 700,
    fontWeightBlack: 800,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: '',
    },
    MuiButton: {
      styleOverrides: {
        root: ({
          theme: {
            unstable_sx: sx,
            palette: { grey },
          },
        }) =>
          sx({
            ':disabled': { bgcolor: grey.main, color: grey.dark },
          }),
      },
    },
  },
};

/** Merge with default theme options and create a theme instance
 * See here for more info: https://mui.com/customization/theming/#api */
const themes: { [type in Device]: Theme | undefined } = {
  mobile: undefined,
  tablet: undefined,
  desktop: undefined,
};

const theme = (device: Device = 'desktop') => {
  if (!themes[device]) {
    themes[device] = createTheme(deepmerge(generateDefaultThemeOptions(device), themeOptions));
  }

  return themes[device];
};

// eslint-disable-next-line import/no-default-export
export default theme;
