import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlist32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M7.651 3.933c-2.837.98-4.937 3.685-5.497 6.82-.334 1.87-.119 3.852.585 5.731.986 2.63 2.475 4.256 6.052 7.232a136.097 136.097 0 0 0 5.861 4.605c.054.04.408.34.401.334l.145.117c.364.286.601.424.891.408.271-.014.509-.166.898-.482l.131-.108c.026-.022.431-.371.485-.411a137.219 137.219 0 0 0 6.134-4.851c3.293-2.771 4.849-4.577 5.739-7.26 1.982-5.971-1.607-12.567-7.732-12.567v1c5.371 0 8.567 5.875 6.782 11.252-.815 2.459-2.272 4.15-5.433 6.81a136.851 136.851 0 0 1-6.09 4.816c-.086.065-.533.45-.529.447l-.117.097c-.19.154-.358.261-.32.259.059-.003-.07-.077-.219-.195a5.313 5.313 0 0 1-.125-.102 18.2 18.2 0 0 0-.441-.365 134.486 134.486 0 0 1-5.819-4.572c-3.447-2.868-4.85-4.399-5.755-6.814-.643-1.715-.838-3.517-.537-5.204.499-2.795 2.363-5.195 4.839-6.051 2.655-.916 5.759-.05 7.681 2.103l.365.409.374-.401c1.359-1.459 3.454-2.489 5.345-2.489v-1c-2.016 0-4.174.983-5.703 2.431-2.2-2.165-5.509-2.991-8.388-1.998z" /></SvgIcon>;
};
const Memo = memo(SvgWishlist32);
export default Memo;