import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgUser32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M24.991 25.62c-.706-.381-3.897-2.103-4.374-2.355a.684.684 0 0 1-.358-.578V21.02c.074-.176.362-.622.539-.895.192-.298.375-.58.492-.801.249-.469.475-1.364.575-1.794.301-.253.791-.772.791-1.466v-2.145c0-.433-.241-.768-.417-1.012a3.523 3.523 0 0 1-.088-.125V9.345c0-.04-.35-3.974-5.961-3.974h-.631c-5.611 0-5.961 3.934-5.961 3.974v3.442a3.838 3.838 0 0 1-.1.147c-.171.244-.405.577-.405.985v2.145c0 .701.523 1.219.849 1.474.093.449.301 1.349.549 1.818.117.222.3.503.492.802.176.273.465.719.539.895v1.667c0 .098-.023.427-.319.559-.523.233-3.527 1.884-4.373 2.35a13.273 13.273 0 0 1-4.233-9.722c0-7.339 5.971-13.31 13.31-13.31s13.311 5.971 13.311 13.31c0 3.83-1.628 7.284-4.225 9.715zM7.86 26.5c1.54-.847 3.505-1.914 3.854-2.07.788-.35 1.069-1.114 1.069-1.712v-1.735c0-.334-.203-.679-.741-1.511-.17-.263-.345-.534-.437-.707-.173-.328-.382-1.225-.473-1.713l-.054-.277-.238-.146c-.203-.125-.485-.4-.485-.565v-2.122c.023-.064.118-.199.176-.282.155-.22.329-.468.329-.781V9.353c.001-.027.405-2.719 4.7-2.719h.631c4.295 0 4.698 2.692 4.7 2.713v3.533c0 .315.173.555.326.767.063.087.167.232.179.274v2.145c0 .183-.257.454-.439.576l-.218.142-.055.254c-.102.48-.332 1.364-.508 1.698-.092.173-.267.445-.437.707-.538.832-.741 1.178-.741 1.512v1.735c0 .53.27 1.291 1.03 1.693.408.216 2.811 1.512 3.931 2.116a13.244 13.244 0 0 1-8.052 2.72 13.24 13.24 0 0 1-8.046-2.716zm8.045-25.167C7.87 1.333 1.333 7.87 1.333 15.905S7.87 30.477 15.905 30.477s14.572-6.537 14.572-14.572S23.94 1.333 15.905 1.333" /></SvgIcon>;
};
const Memo = memo(SvgUser32);
export default Memo;