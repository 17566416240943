import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCart32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M12.4 9.9c0 .4-.4.8-.8.8s-.8-.4-.8-.8.4-.8.8-.8.8.4.8.8" /><path d="M24.2 7.9h-4.3s.5-4.5-3.8-4.6h-.2c-4.1.1-3.8 4.6-3.8 4.6H7.8l-1 20.9h18.4zM15.5 17l-5-5.1v-3h3.1l5.1 5.1zm.6-9.1h-3.2c0-3.5 2.3-3.7 3-3.7h.2c.7 0 3 .2 3 3.7z" /></SvgIcon>;
};
const Memo = memo(SvgCart32);
export default Memo;